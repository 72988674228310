// Main
import "./modules/bootstrap";
// import "./modules/feather";
// import "./modules/fullcalendar";
import "./modules/moment";
import "./modules/sidebar";
import "./modules/splash";
import "./modules/theme";
import "./modules/toastr";
// import "./modules/user-agent";

// Pages
// import "./modules/dragula";

// Charts
import "./modules/chartjs";
// import "./modules/apexcharts";

// Forms
// import "./modules/daterangepicker";
// import "./modules/datetimepicker";
// import "./modules/mask";
// import "./modules/quill";
// import "./modules/select2";
import "./modules/validation";
// import "./modules/wizard";

// Maps
// import "./modules/vector-maps";

// Tables
// import "./modules/datatables";
